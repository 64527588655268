var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addRace"},[_c('div',[_c('el-form',{ref:"elForm",attrs:{"model":_vm.formData,"size":"medium","label-width":"100px"}},[_c('div',{staticClass:"fileBox"},_vm._l((_vm.formData.scheduleDtoList),function(item,index){return _c('el-row',{key:index,staticClass:"rowBox",attrs:{"gutter":15}},[(
              item.scheduleName !== 4 &&
              item.scheduleName !== 5 &&
              item.scheduleName !== 6
            )?_c('div',[_c('el-form-item',{staticClass:"item1",attrs:{"label":"赛程阶段"}},[_c('el-select',{style:({ width: '30%' }),attrs:{"placeholder":"请选择赛程阶段","clearable":""},model:{value:(item.scheduleName),callback:function ($$v) {_vm.$set(item, "scheduleName", $$v)},expression:"item.scheduleName"}},_vm._l((_vm.options),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id,"disabled":item.disabled}})}),1)],1),_c('el-form-item',{attrs:{"label":"射击发数"}},[_c('el-input-number',{style:({ width: '10%' }),attrs:{"precision":0,"max":600,"min":1},model:{value:(item.shootCount),callback:function ($$v) {_vm.$set(item, "shootCount", $$v)},expression:"item.shootCount"}})],1),_c('el-form-item',{attrs:{"label":"记成绩发数"}},[_c('el-input-number',{style:({ width: '10%' }),attrs:{"precision":0,"max":item.shootCount,"min":1},model:{value:(item.scoreCount),callback:function ($$v) {_vm.$set(item, "scoreCount", $$v)},expression:"item.scoreCount"}})],1),_c('el-form-item',{attrs:{"label":"比赛限时(分钟)"}},[_c('el-input-number',{style:({ width: '10%' }),attrs:{"precision":0,"max":600,"min":30},model:{value:(item.ompetitionTime),callback:function ($$v) {_vm.$set(item, "ompetitionTime", $$v)},expression:"item.ompetitionTime"}})],1),_c('el-form-item',{attrs:{"label":"比赛时间"}},[_c('el-date-picker',{style:({ width: '30%' }),attrs:{"type":"datetimerange","placement":"bottom-start","format":"yyyy-MM-dd HH:mm","value-format":"yyyy-MM-dd HH:mm","start-placeholder":"开始日期","end-placeholder":"结束日期","range-separator":"至","clearable":"","default-time":['9:00:00', '18:00:00'],"picker-options":_vm.setDateRange},model:{value:(item.timeStol),callback:function ($$v) {_vm.$set(item, "timeStol", $$v)},expression:"item.timeStol"}})],1)],1):_vm._e(),(
              _vm.formData.scheduleDtoList.length > 1 &&
              item.scheduleName !== 4 &&
              item.scheduleName !== 5 &&
              item.scheduleName !== 6
            )?_c('div',{staticClass:"myline",staticStyle:{"height":"1px","width":"35%","margin":"20px","border-bottom":"1px solid #000"}}):_vm._e(),(index + 1 === _vm.formData.scheduleDtoList.length)?_c('el-button',{staticClass:"addBtnBox",attrs:{"type":"primary"},on:{"click":_vm.addRow}},[_vm._v("新增")]):_vm._e(),(_vm.formData.scheduleDtoList.length > 1)?_c('el-button',{staticClass:"delBtnBox",attrs:{"type":"primary"},on:{"click":function($event){return _vm.delRow(index)}}},[_vm._v("删除")]):_vm._e()],1)}),1),_c('el-form-item',{staticClass:"btnBox",attrs:{"size":"large"}},[_c('el-button',{staticClass:"resBox",on:{"click":_vm.resetForm}},[_vm._v("取消")]),_c('el-button',{staticClass:"subBox",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.submitForm}},[_vm._v("提交")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }